<template>
  <div class="home">

    <!-- <navbar /> -->
    <div class="naglowek">
        <h1>Harmonogram</h1>
    </div>

    <div class="content">

        <div v-if="this.loadedDaysNumber==0">

          <loadingItem class="loading" medium />

          </div>

        <div v-else>



          <div  class="dayList">

            <day
              v-for="(day, index) in this.printedDays"
              v-bind:key="index"
              :year="day.year"
              :month="day.month"
              :day="day.day"
              :name="day.name"
              :lessons="day.lessons"
              :firstDay="index == 0"
              :even="index % 2 == 0"
            />

            <trigger v-if="this.displayBottomTrigger" @triggerIntersected="loadMore" />

          </div>

        </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import day from "@/components/day.vue";
import trigger from "@/components/trigger.vue";
//import navbar from "@/components/navbar.vue";
import loadingItem from "@/components/loadingItem.vue";


export default {
  name: "Home",
  components: {
    day,
    trigger,
    //navbar,
    loadingItem,
  },
  data() {
    return {
      days: [],
      printedDays: [],
      futureDays: [],
      loadedDaysNumber: 0,
      displayBottomTrigger: false,
      closestDayLesson: null,

      //menuOption: 1,

     
    };
  },
  
  created() {

    // bez tego opóźnienia aplikacja od razu doładowywała drugi raz plan.
    setTimeout(() => {
      this.displayBottomTrigger = true;
    }, 100);

    // wczytanie zajec z cache
    const savedDays = this.$func.getSavedLessons();

    if(!savedDays){ // nieudalo sie wczytac
    
      axios.get("/plan2.html").then((response) => {
        let result_table = new DOMParser().parseFromString(
          response.data,
          "text/xml"
        );

        let list =
          result_table.firstChild.getElementsByTagName("tbody")[0].children;

        //console.log(list[0]);//.children[2].firstChild);

        for (let i = 0; i < list.length; ++i) {
          this.addTr(list[i]);
        }

        // sortowanie lekcji w dniu
        for (let i = 0; i < this.days.length; ++i) {
          this.days[i].lessons.sort(this.$func.sortHours);
        }
        // sortowanie dni
        this.days.sort(this.$func.sortDates);

        // zapisanie zajec do cache
        //this.$func.saveLessons({'days': this.days}); // todo todo todo todo todo todo todo todo todo todo todo todo ----- zapisywanie w cookies plan zajec
        this.afterCreate();

      });

    } else{ // udalo sie wczytac
    
      this.days = savedDays;
      console.log(this.days);
      this.afterCreate();
    }

    
      
    
  },
  methods: {

    afterCreate(){
      this.closestDayLesson = this.$func.findClosestLesson(this.days);

      // wrzucanie do futureDays tylko przyszłych dni (lub dzisiaj)
      this.futureDays = this.days.filter((currentVal, index) => {
        if (index >= this.closestDayLesson.closestDay) return true;
        return false;
      });

      // wrzucanie do futureDays[0] tylko tych lekcji, które jeszcze nie minęły
      this.futureDays[0].lessons = this.futureDays[0].lessons.filter(
        (currentVal, index) => {
          if (index >= this.closestDayLesson.closestLesson) return true;
          return false;
        }
      );

      // wyświetlenie kilku najbliższych dni
      this.printedDays = this.futureDays.slice(0, 5);
      this.loadedDaysNumber = this.printedDays.length;

      // opóźnienie po to, aby wczesniej zdazylo sie wykonac początek funkcji create
      setTimeout(() => {
      if(this.printedDays.at(-1) == this.futureDays.at(-1))
          this.displayBottomTrigger = false;
      }, 100);
        

      
      // odblokowanie wyświetlania danego widoku (route'a)
      // opóźnienie po to, aby pasek ładowania nie migał zbyt szybko
      setTimeout(() => {
          this.$root.loading = false;
      }, 400);

      console.log(this.$root)

      console.log(this.printedDays);
      console.log(this.loadedDaysNumber);
      console.log("closest:");
      console.log(this.closestDayLesson.closestDay);
      console.log(this.closestDayLesson.closestLesson);

      //
      //this.addTr(list[0]);
      //this.addTr(list[0]);
    },

    addTr(tr) {
      //console.log(tr.children[3].firstChild.textContent);
      let date = tr.children[2].firstChild.textContent.split("-");
      let dateName = tr.children[3].firstChild.textContent;

      let indexOfDay = this.$func.findDay(date, this.days);

      let tmpTimeStart = tr.children[4].firstChild.textContent.split(":");
      let tmpTimeEnd = tr.children[5].firstChild.textContent.split(":");

      let przedmiot = tr.children[0].firstChild.textContent;
      let prowadzacy = tr.children[1].firstChild.textContent;
      let godzinaStart = tmpTimeStart[0];
      let minutaStart = tmpTimeStart[1];
      let godzinaKoniec = tmpTimeEnd[0];
      let minutaKoniec = tmpTimeEnd[1];
      let sala = tr.children[6].firstChild.textContent.trimStart();
      let grupa = tr.children[7].firstChild.textContent;

      let lesson = {
        przedmiot: przedmiot,
        prowadzacy: prowadzacy,
        godzinaStart: godzinaStart,
        minutaStart: minutaStart,
        godzinaKoniec: godzinaKoniec,
        minutaKoniec: minutaKoniec,
        sala: sala,
        grupa: grupa,
      };

      if (indexOfDay != -1) {
        // znaleziono juz ten dzien, dodajemy zajecia do istniejacego dnia
        this.days[indexOfDay].lessons.push(lesson);
      } else {
        // tworzymy nowy dzień i dodajemy zajęcia do nowego dnia
        let day = {
          day: date[2],
          month: date[1],
          year: date[0],
          name: this.$func.getFullDayName(dateName),
          lessons: [],
        };

        day.lessons.push(lesson);

        this.days.push(day);
      }
    },

    async loadMore() {

      console.log("widzoczny")

      setTimeout(() => {
        
        // wyświetlenie kilku najbliższych dni
        this.printedDays = this.futureDays.slice(
          0,
          this.loadedDaysNumber + 5
        );
        this.loadedDaysNumber = this.printedDays.length;

        if(this.printedDays.at(-1) == this.futureDays.at(-1))
          this.displayBottomTrigger = false;
      }, 400);

    },

    async loadOld(){
        console.log("laasfa")

    },
  },
};
</script>
<style lang="scss" scoped>

.dayList{
  
  
}

</style>
