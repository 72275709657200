<template>
  <div class="firstLesson">
    <div class="kolejneZajecia">KOLEJNE ZAJĘCIA:</div>

    <div class="przedmiot">{{ this.przedmiot }}</div>

    <div class="grupa">
      <div class="grupaLabel">Prowadzący:</div>
      <div class="grupaString">{{ this.grupa }}</div>
    </div>

    <div class="czas">
      {{ this.godzinaStart }}:{{ this.minutaStart }} -
      {{ this.godzinaKoniec }}:{{ this.minutaKoniec }}
    </div>

    <div class="sala">{{ this.sala }}</div>
  </div>
</template>

<script>
export default {
  name: "firstLesson",
  props: {
    przedmiot: String,
    prowadzacy: String,
    godzinaStart: String,
    minutaStart: String,
    godzinaKoniec: String,
    minutaKoniec: String,
    sala: String,
    grupa: String,

    firstLesson: Boolean,
  },
  components: {},
  methods: {},
};
</script>
<style scoped lang="scss">
.firstLesson {
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  border-color: #333;

  padding-top: 5px;
  //padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;

  background-color: var(--bottom_gradient);
  color: white;
  width: 60%;
  height: 370px;
}

.przedmiot {
  font-size: 30px;
}
.grupaLabel {
  margin-bottom: 5px;
}
.grupaString {
  font-size: 24px;
}
.czas {
  font-size: 25px;
}
.sala {
  font-size: 20px;
  padding: 15px;
}
.kolejneZajecia {
  font-size: 18px;
}
.sala {
  margin-bottom: 20px;
}
</style>
