<template>
  <table class="lesson">
    <tr>
      <td class="left">
        <div>
          <div class="przedmiot">{{ this.przedmiot }}</div>
        </div>
      </td>
      <td class="right">
        <div>
          <div class="czas">
            {{ godzinaStart }}:{{ minutaStart }} - {{ godzinaKoniec }}:{{
              minutaKoniec
            }}
          </div>
          <div>
            <span class="grupaOpis">gr. </span
            ><span class="grupa">{{ grupa }}</span>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "lesson",
  props: {
    przedmiot: String,
    prowadzacy: String,
    godzinaStart: String,
    minutaStart: String,
    godzinaKoniec: String,
    minutaKoniec: String,
    sala: String,
    grupa: String,
  },
  components: {},
  methods: {},
};
</script>
<style scoped>
.lesson {
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  border-color: #444;

  color: white;
  width: 93%;
  height: 80px;

  _float: left;
}

.left {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  height: 100%;
}
.right {
  width: 35%;
  height: 100%;
  text-align: left;
}

.przedmiot {
  padding-left: 20px;
  padding-right: 10px;
}

.czas {
  font-size: 15px;
}

.grupa {
  _font-size: ;
}

.grupaOpis {
  font-size: 15px;
}

.even {
  background-color: var(--top_gradient);
}

.odd {
  background-color: var(--bottom_gradient);
}
</style>
