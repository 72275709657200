<template>
  <div ref="trigger">
      <loadingItem small />
  </div>
</template>

<script>
/*
export default {
  name: "trigger",
  props: {},
  components: {},
  methods: {},
};

*/
import loadingItem from "@/components/loadingItem.vue";
 
export default {
  components: {
    loadingItem,
  },

  props: {
    options: {
      type: Object,
      default: () => {
        null;
        ("0");
      },
    },
  },

  data() {
    return {
      observer: null,
    };
  },

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.handleIntersect(entries[0]);
    }, this.options);

    this.observer.observe(this.$refs.trigger);
  },

  methods: {
    handleIntersect(entry) {
      if (entry.isIntersecting) this.$emit("triggerIntersected");
    },
  },

  beforeDestroy() {
    this.observer.disconnect();
  },
};
</script>
<style scoped></style>
