<template>
  <div class="day">
    <div class="describe">
      <div class="date_name">{{ this.name }}</div>
      <div class="date">{{ this.day }}.{{ this.month }}.{{ this.year }}</div>
    </div>
    <div class="lessons">
      <div v-for="(lesson, index) in this.lessons" v-bind:key="index">
        <firstLesson
          v-if="firstDay && index == 0"
          :przedmiot="lesson.przedmiot"
          :prowadzacy="lesson.prowadzacy"
          :godzinaStart="lesson.godzinaStart"
          :minutaStart="lesson.minutaStart"
          :godzinaKoniec="lesson.godzinaKoniec"
          :minutaKoniec="lesson.minutaKoniec"
          :sala="lesson.sala"
          :grupa="lesson.prowadzacy"
        />

        <lesson
          v-else
          :przedmiot="lesson.przedmiot"
          :prowadzacy="lesson.prowadzacy"
          :godzinaStart="lesson.godzinaStart"
          :minutaStart="lesson.minutaStart"
          :godzinaKoniec="lesson.godzinaKoniec"
          :minutaKoniec="lesson.minutaKoniec"
          :sala="lesson.sala"
          :grupa="lesson.grupa"
          :class="{ even: even, odd: !even }"
        />
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import lesson from "@/components/lesson.vue";
import firstLesson from "@/components/firstLesson.vue";

export default {
  name: "day",
  props: {
    year: String,
    month: String,
    day: String,
    name: String,
    lessons: [],

    firstDay: Boolean,
    even: Boolean,
  },
  components: {
    lesson,
    firstLesson,
  },
  methods: {},
};
</script>
<style scoped>
* {
  font-family: sans-serif;
}

.describe {
  height: 10px;
  padding: 20px;
  font-family: sans-serif;
  font-weight: bold;
  color: gray;
  font-size: 18px;
}

.date_name {
  float: left;
}

.date {
  float: right;
}
</style>
